import classnames from "classnames";
import React, { useState } from "react";

import {
	Button,
	getPayloadObject,
	getTelemetryAttributes,
	ITelemetryContent,
	TelemetryConstant,
} from "@msdyn365-commerce-modules/utilities";
import {
	IComponent,
	IComponentProps,
	msdyn365Commerce,
} from "@msdyn365-commerce/core";

//Saqlain Customization - This import was changed from /retail-actions to custom data action in actions directory//
import {
	addOrderTemplateToCartAction,
	AddOrderTemplateToCartInput,
} from "./../../../../actions/britax-add-order-template-to-cart.action";

import { OrderTemplate } from "@msdyn365-commerce-modules/retail-actions";

import { toast } from "react-toastify"; //VSI CUSTOMIZATION

export interface IAddOrderTemplateToCartComponentData {
	orderTemplate: OrderTemplate;
}

export interface IAddOrderTemplateToCartComponentProps
	extends IComponentProps<IAddOrderTemplateToCartComponentData> {
	className?: string;
	addToCartText: string;
	telemetryContent?: ITelemetryContent;
	dialogStrings: {
		closeWindowButtonText: string;
		addToCartFailureMessage: string;
		addToCartSuccessMessage: string;
		addToCartProcessMessage: string;
	};
}

export declare type IAddToCartActionFailureReason =
	| "EMPTYINPUT"
	| "MISSINGDIMENSION"
	| "OUTOFSTOCK"
	| "CARTACTIONFAILED"; // Rename to used method

export interface IAddOrderTemplateToCartComponent
	extends IComponent<IAddOrderTemplateToCartComponentProps> {
	onClick(): (
		event: React.MouseEvent<HTMLElement>,
		props: IAddOrderTemplateToCartComponentProps
	) => void;
}

/* 
    VSI CUSTOMIZATION - START
    openNotification() => pop up message when Add Template to Cart is Successful
    openNotificationError() => pop up message when Add Template to Cart is UnSuccessful
*/
const openNotification = (props: IAddOrderTemplateToCartComponentProps) => {
	toast.dark(
		<>
			<h5>{props.dialogStrings.addToCartSuccessMessage}</h5>
		</>,
		{
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		}
	);
};

const openNotificationError = (
	props: IAddOrderTemplateToCartComponentProps
) => {
	toast.error(props.dialogStrings.addToCartFailureMessage, {
		position: "top-right",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};
/* 
    VSI CUSTOMIZATION - END
*/

const onClick = async (
	_event: React.MouseEvent<HTMLElement>,
	props: IAddOrderTemplateToCartComponentProps,
	setAddedLinesCount: (value: any) => any
): Promise<void> => {
	const { context } = props;
	const addOrderTemplateToCartInput = new AddOrderTemplateToCartInput(
		props.data.orderTemplate.productList.Id
	);

	await addOrderTemplateToCartAction(
		addOrderTemplateToCartInput,
		context.actionContext
	)
		/* VSI CUSTOMIZATION - START */
		.then(() => {
			openNotification(props);
		})
		.catch((e: Error) => {
			openNotificationError(props);
		});
	/* VSI CUSTOMIZATION - END */
};

const AddToCartComponentActions = {
	onClick,
};

const AddOrderTemplate: React.FC<any> = (props: any) => {
	const [setUploadStatus] = useState<any>("WAITING");

	const onClickHandler = async (event: React.MouseEvent<HTMLElement>) => {
		await AddToCartComponentActions.onClick(event, props, setUploadStatus);
	};

	const payLoad = getPayloadObject(
		"click",
		props.telemetryContent!,
		TelemetryConstant.AddOrderTemplateToCart
	);
	const addOrderTemplateAttribute = getTelemetryAttributes(
		props.telemetryContent!,
		payLoad
	);

	return (
		<>
			<Button
				className={classnames(
					"msc-add-order-template-to-cart ",
					props.className
				)}
				aria-label={props.addToCartText}
				disabled={props.data.orderTemplate.totalLines === 0}
				onClick={onClickHandler}
				{...addOrderTemplateAttribute}
			>
				{props.addToCartText}
			</Button>
		</>
	);
};

export const AddOrderTemplateToCartComponent: React.FunctionComponent<IAddOrderTemplateToCartComponentProps> = msdyn365Commerce.createComponentOverride<
	any
>("AddOrderTemplate", {
	component: AddOrderTemplate,
	...AddToCartComponentActions,
});

export default AddOrderTemplateToCartComponent;
