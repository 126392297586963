import {getProductPageUrlSync} from "@msdyn365-commerce-modules/retail-actions";
import {
  format,
  getPayloadObject,
  getTelemetryAttributes,
  ITelemetryContent,
  onTelemetryClick,
} from "@msdyn365-commerce-modules/utilities";
import {
  IComponent,
  IComponentProps,
  ICoreContext,
  IGridSettings,
  IImageData,
  IImageSettings,
  Image,
  msdyn365Commerce,
} from "@msdyn365-commerce/core";
import {ProductSearchResult} from "@msdyn365-commerce/retail-proxy";
import React from "react";
import {RatingComponent} from "@msdyn365-commerce/components";

export interface IProductComponentProps
  extends IComponentProps<{product?: ProductSearchResult}> {
  className?: string;
  imageSettings?: IImageSettings;
  savingsText?: string;
  freePriceText?: string;
  originalPriceText?: string;
  currentPriceText?: string;
  ratingAriaLabel?: string;
  allowBack?: boolean;
  telemetryContent?: ITelemetryContent;
  quickViewButton?: React.ReactNode;
}

export interface IProductComponent extends IComponent<IProductComponentProps> {}

const PriceComponentActions = {};

const ProductCard: React.FC<any> = (props: any) => {
  const {
    data,
    context,
    imageSettings,
    ratingAriaLabel,
    allowBack,
    typeName,
    id,
    telemetryContent,
    quickViewButton,
  } = props;

  const product = data.product;

  if (!product) {
    return null;
  }

  let productUrl = getProductPageUrlSync(
    product.Name || "",
    product.RecordId,
    context && context.actionContext,
    undefined,
  );
  if (allowBack) {
    productUrl = updateProductUrl(productUrl, context);
  }

  // Construct telemetry attribute to render
  const payLoad = getPayloadObject(
    "click",
    telemetryContent!,
    "",
    product.RecordId.toString(),
  );

  const attribute = getTelemetryAttributes(telemetryContent!, payLoad);

  // const onAddToOrderTemplateFailed = (result: IOrderTemplateActionErrorResult) => {
  // callbacks.updateErrorState({
  // errorHost: 'ORDER_TEMPLATE',
  // configureErrors: result.status === 'MISSING_DIMENSION' ? getConfigureErrors(result.missingDimensions, productContextData.resources, product?.IsGiftCard) : {},
  // });
  // };

  if (
    typeof window !== "undefined" &&
    typeof document !== "undefined" &&
    typeof localStorage !== "undefined"
  ) {
    return (
      <>
        {quickViewButton && renderQuickView(quickViewButton, product.RecordId)}
        <a
          href={productUrl}
          onClick={onTelemetryClick(telemetryContent!, payLoad, product.Name!)}
          aria-label={renderLabel(
            product.Name,
            context.cultureFormatter.formatCurrency(product.Price),
            product.AverageRating,
            ratingAriaLabel,
          )}
          className="msc-product"
          {...attribute}
        >
          <div className="msc-product__image">
            {renderProductPlacementImage(
              imageSettings,
              context.request.gridSettings,
              product.PrimaryImageUrl,
              product.Name,
            )}
          </div>
          <div className="msc-product__details">
            <h4 className="msc-product__title">{product.Name}</h4>

            {/* {!window.location.href.toString().includes("search") ||
          window.location.href.toString().includes("/all")
            ? null
            : renderPrice(
                context,
                typeName,
                id,
                product.BasePrice,
                product.Price,
                savingsText,
                freePriceText,
                originalPriceText,
                currentPriceText,
                props.currencyDynmaicCode
              )} */}

            <a
              href={productUrl}
              onClick={onTelemetryClick(
                telemetryContent!,
                payLoad,
                product.Name!,
              )}
              className="msc-product msc-product__btn msc-btn"
              {...attribute}
            >
              {props.viewDetails}
            </a>

            {renderDescription(product.Description)}
            {!context.app.config.hideRating &&
              renderRating(
                context,
                typeName,
                id,
                product.AverageRating,
                product.TotalRatings,
                ratingAriaLabel,
              )}

            {/* {typeName === "product-collection" &&
          productContextData.data.orderTemplates && (
            <AddToOrderTemplateComponent
              className={"msc-add-to-cart-extra-actions"}
              addToOrderTemplateButtonText="Add to Order Template"
              addToOrderTemplateButtonTooltip="This product cannot be added to order template due to custom pricing"
              addToOrderTemplateDialogResources={{
                addToOrderTemplateHeader: "Add to Order Template",
                addToTemplateButtonText: "Add to Template",
                cancelOrderTemplateCreationButtonText: "Cancel",
                createAnOrderTemplateButtonText: "Create an Order Template",
                createNewOrderTemplateButtonText: "Create New",
                lineItemsText: "{0} line item(s)",
                noOrderTemplatesDescription:
                  "Order templates will be saved to your account. You can reuse them in the future to speed up the checkout process. Create an order template and add this item to it.",
                noOrderTemplatesMessage:
                  "You have not created any order templates.",
                selectTemplatesText:
                  "Select a template or create a new one. You have {0} template(s).",
              }}
              createOrderTemplateDialogResources={{
                defaultOrderTemplateName: "Untitled",
                orderTemplateButtonLabel: "Create",
                orderTemplateCancelButtonLabel: "Cancel",
                orderTemplateDescription:
                  "Order templates will be saved to your account. You can reuse them in the future to speed up the checkout process. Create an order template and add this item to it.",
                orderTemplateHeaderLabel: "Create an order template",
                orderTemplateNameAriaLabel: "Order template title",
                orderTemplateTitleLabel: "Order template title",
              }}
              itemAddedToOrderTemplateDialogResources={{
                continueShoppingButtonText: "Continue shopping",
                currentPriceText: "Current price",
                freePriceText: "Free",
                itemAddedToOrderTemplateHeaderItemFormatText: "{0} items",
                itemAddedToOrderTemplateHeaderItemOneText: "1 item",
                itemAddedToOrderTemplateHeaderMessageText:
                  " added to order template",
                originalPriceText: "Original price",
                viewOrderTemplateButtonText: "View order template",
              }}
              duplicateItemsWhenAddingToOrderTemplateDialogResources={{
                cancelDuplicateItemsButtonText: "Cancel",
                duplicatedProductsDescription:
                  "This product has been added to the selected order template in the past. Do you want to update the product quantity in the order template?",
                duplicatedProductsHeader: "Duplicated products",
                updateQuantityButtonText: "Yes, update quantity",
              }}
              data={{
                orderTemplates: productContextData.data.orderTemplates.result,
                product: {
                  BasePrice: 123,
                  RecordId: product.RecordId,
                  ProductTypeValue: 123,
                  AdjustedPrice: 213,
                  Price: product.Price,
                },
                quantity: 1,
                customerInformation: {
                  IsB2b: true,
                  AccountNumber: "123213213",
                },
              }}
              context={context}
              id={id}
              typeName={typeName}
              // onError={onAddToOrderTemplateFailed}
              shouldShowButtonFailedTooltip={false}
            />
          )} */}
          </div>
        </a>
      </>
    );
  } else {
    return <></>;
  }
};

function renderLabel(
  name?: string,
  price?: string,
  rating?: number,
  ratingAriaLabel?: string,
): string {
  name = name || "";
  price = price || "";
  return `${name} ${price} ${getRatingAriaLabel(rating, ratingAriaLabel)}`;
}

function renderDescription(description?: string): JSX.Element | null {
  return <p className="msc-product__text">{description}</p>;
}

function renderQuickView(
  quickview: React.ReactNode,
  item?: number,
): JSX.Element | undefined {
  if (quickview === null) {
    return undefined;
  }
  return React.cloneElement(quickview as React.ReactElement, {
    selectedProductId: item,
  });
}

function getRatingAriaLabel(rating?: number, ratingAriaLabel?: string): string {
  if (rating && ratingAriaLabel) {
    const roundedRating = rating.toFixed(2);
    return format(ratingAriaLabel || "", roundedRating, "5");
  }
  return "";
}

function updateProductUrl(productUrl: string, context: ICoreContext): string {
  const srcUrl = new URL(productUrl, context.request.apiSettings.baseUrl);
  const queryString = "back=true";
  if (srcUrl.search) {
    srcUrl.search += `&${queryString}`;
  } else {
    srcUrl.search += queryString;
  }

  const updatedUrl = new URL(srcUrl.href);
  return updatedUrl.pathname + srcUrl.search;
}

function renderRating(
  context: ICoreContext,
  typeName: string,
  id: string,
  avgRating?: number,
  totalRatings?: number,
  ariaLabel?: string,
): JSX.Element | null {
  if (!avgRating) {
    return null;
  }

  const numRatings = (totalRatings && totalRatings.toString()) || undefined;
  const ratingAriaLabel = getRatingAriaLabel(avgRating, ariaLabel);

  return (
    <RatingComponent
      context={context}
      id={id}
      typeName={typeName}
      avgRating={avgRating}
      ratingCount={numRatings}
      readOnly
      ariaLabel={ratingAriaLabel}
      data={{}}
    />
  );
}

// function renderPrice(
//   context: ICoreContext,
//   typeName: string,
//   id: string,
//   basePrice?: number,
//   adjustedPrice?: number,
//   savingsText?: string,
//   freePriceText?: string,
//   originalPriceText?: string,
//   currentPriceText?: string,
//   currencyCode?:any
// ): JSX.Element | null {
//   const price: ProductPrice = {
//     BasePrice: basePrice,
//     AdjustedPrice: adjustedPrice,
//     CustomerContextualPrice: adjustedPrice,
//   };
//   return (
//     <PriceComponent
//       context={context}
//       id={id}
//       typeName={typeName}
//       data={{ price }}
//       savingsText={savingsText}
//       freePriceText={freePriceText}
//       originalPriceText={originalPriceText}
//       currencyCode={currencyCode}
//     />
//   );
// }

function renderProductPlacementImage(
  imageSettings?: IImageSettings,
  gridSettings?: IGridSettings,
  imageUrl?: string,
  altText?: string,
): JSX.Element | null {
  const defaultImageSettings: IImageSettings = {
    viewports: {
      xs: {q: `w=300&h=300&m=6`, w: 0, h: 0},
      lg: {q: `w=300&h=300&m=6`, w: 0, h: 0},
      xl: {q: `w=300&h=300&m=6`, w: 0, h: 0},
    },
    lazyload: true,
    cropFocalRegion: true,
  };
  if (!imageUrl || !gridSettings || !imageSettings) {
    return null;
  }
  const img: IImageData = {
    src: imageUrl,
    altText: altText ? altText : "",
  };
  const imageProps = {
    gridSettings,
    imageSettings: imageSettings || defaultImageSettings,
  };
  imageProps.imageSettings.cropFocalRegion = true;
  return <Image {...img} {...imageProps} loadFailureBehavior="empty" />;
}

export const ProductComponent: React.FunctionComponent<any> = msdyn365Commerce.createComponentOverride<
  any
>("Product", {component: ProductCard, ...PriceComponentActions});

export default ProductComponent;

// function getConfigureErrors(result: ProductDimension[] | undefined, resources: IBuyboxCommonResources, isGiftCard?: Boolean): { [configureId: string]: string | undefined } {
// if (!result) {
// return {};
// }

// const dimensions: { [configureId: string]: string | undefined } = {};

// result.forEach(dimension => {
// dimensions[dimension.DimensionTypeValue] = getDimensionErrorString(dimension.DimensionTypeValue, resources, isGiftCard);
// });

// return dimensions;
// }

// function getDimensionErrorString(dimensionTypeValue: number, resources: IBuyboxCommonResources, isGiftCard?: Boolean): string {
// switch (dimensionTypeValue) {
// case 1: // ProductDimensionType.Color
// return resources.productDimensionTypeColorErrorMessage;
// case 2: // ProductDimensionType.Configuration
// return resources.productDimensionTypeConfigurationErrorMessage;
// case 3: // ProductDimensionType.Size
// return resources.productDimensionTypeSizeErrorMessage;
// case 4: // ProductDimensionType.Style
// return isGiftCard ? resources.productDimensionTypeAmountErrorMessage : resources.productDimensionTypeStyleErrorMessage;
// default:
// return '';
// }
// }
